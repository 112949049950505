import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import layout from "./layout";
import admin from "./admin";
import users from "./users";
import category from "./category";
import promotion from "./promotion";
import activity from "./activity";
import evaluation from "./evaluation";
import whatsapp from "./whatsapp";

import group from "./group";
import region from "./region";

export default new Vuex.Store({
    "modules": {
        layout,
        admin, users,
        group, region,
        category, promotion,
        "event": activity,
        evaluation,
        whatsapp,
    },
});
