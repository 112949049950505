export default {
    "zh-hk": {
        message: {
            toasts: {
                offline: {
                    title: "網站離線",
                    content: "本網站目前處於離線狀態。網站上的信息可能已過期。",
                },

                update: {
                    title: "有可用更新",
                    content: "點擊更新👉",
                    button: "重新載入",
                },
            },

            navbar: {
                menu: "選單",
                cart: "購物籃"
            },

            sidebar: {
                register: "註冊",
                login: "登入",
                logout: "登出",
                account: "我的帳戶",

                home: "首頁",
                shop: "產品",
                about: "關於",
                contact: "聯絡",

                delivery: "送貨服務",
                faq: "常見問題",
                disclaimer: "條款及細則"
            },

            basketBar: {
                title: "購物籃",
                checkout: "結帳",
                deleteItem: "刪除",
                memberRequired: "請先登入或註冊",
            },

            checkout: {
                title: "結帳",
                delivery: "運費",
                subTotal: "金額",
                total: "總計",
                myOrder: "我的訂單",
                products: "種產品",
                items: "項",
                contact: "聯絡方法",
                "contact-email-notice": "訂單確認通知將發送到此電郵地址。",
                "contact-phone-notice": "我們可能會透過這個電話號碼與閣下聯繫。",
                "delivery-address": "送貨地址",
                "delivery-method": "送貨服務",
                "delivery-standard": "標準郵遞",
                paymentMethod: "付款方法",
                saveAddress: "保存我的地址以便將來更快完成結賬。",
                saveCard: "保存我的付款卡以便將來更快完成結賬。",
                "first-name": "名字",
                "last-name": "姓氏",
                "email": "電郵地址",
                "phone": "電話號碼",
                "address": "地址",
                "optional": "可選",
                "country": "國家 / 地區",
                "postcode": "郵政編碼",
                "next": "下一步",
                confirm: "確認",
                confirmOrder: "確認並付款",
                "edit": "編輯",
                "add": "新增",
                "add-address": "新增地址",
                "add-card": "新增付款卡",
                "add-another-card": "使用另一張卡",
                "accept-tc-1": "我接受",
                "accept-tc-2": "條款及細則",
                "accept-tc-3": "。",
                thankYou: "多謝惠顧！",
                paymentMethods: {
                    cards: "信用卡/預付卡",
                    alipay: "支付寶",
                    wechatPay: "微信支付",
                },
                paymentInProgress: "付款進行中...",
                paymentFailed: "付款失敗。",
                "cancelPayment": "取消付款",
                "retry-payment": "再試一次？",
                confirmEmailSent1: "訂單確認已發送到您的電子郵件地址：",
                confirmEmailSent2: "",
            },

            login: {
                create_account: "建立帳戶",
                register: "註冊",
                registerLine1: "以你的電子郵件",
                login: "登入",
                loginLine1: "以你的社交帳戶",
                loginLine2: "或以你的電子郵件",
                accept_tc_line1: "我接受",
                accept_tc_line2: "條款及細則",
                accept_tc_line3: "。",
                logout: "登出",
                name: "姓名",
                email: "電郵地址",
                password: "密碼",

                forgetPassword: {
                    title: "忘記密碼？",
                    content: "請輸入你註冊時使用的電郵地址。我們會向該電郵地址發送一封包含重置密碼指示的電子郵件。",
                    confirm: "確認",
                },

                verifyOTP: {
                    title: "驗證安全碼",
                    description: "請輸入由單次密碼應用程序生成的6位數安全碼以進行驗證：",
                    verify: "驗證",
                },

                overlay: {
                    register: {
                        title: "歡迎加入！",
                        subtitle: "立即註冊，享受更多",
                    },
                    login: {
                        title: "歡迎回來！",
                        subtitle: "立即登入你的帳戶",
                    }
                },
            },

            account: {
                add: "新增",
                edit: "編輯",
                verify: "驗證",
                enable: "啟用",
                disable: "停用",

                greetings: {
                    morning: "早晨！",
                    afternoon: "午安！",
                    evening: "晚安！",
                },

                spending: {
                    type: {
                        purchase: "購買",
                        preorder: "預購",
                    },
                    totalLine1: "以下是你截至",
                    totalLine2: "的消費額：",
                },

                settings: {
                    title: "帳戶設定",
                    details: {
                        title: "帳戶資料",
                        name: "姓名",
                        email: "電郵地址",
                        lang: "語言",

                        changeName: {
                            title: "更改姓名",
                            new: "新姓名",
                            password: "請輸入你的密碼確認：",
                        },

                        changeEmail: {
                            title: "更改電郵地址",
                            new: "新電郵地址",
                            password: "請輸入你的密碼確認：",
                            description: "驗證郵件已發送到你的新電郵地址。在你驗證新的電郵地址之前，你的帳戶將保持為未經驗證。",
                        },
                    },
                    security: {
                        title: "安全",
                        password: "密碼",

                        twoFactorAuth: "雙重認證",
                        enable2FA: "啟用雙重認證",
                        disable2FA: "停用雙重認證",

                        activateOTP: {
                            title: "單次密碼",
                            subtitle: "支援單次密碼應用程式，例如Google身份驗證器，Microsoft身份驗證器及Authy。",
                            description: "使用你選用的單次密碼應用程式掃描上述QR碼或以手動輸入：",
                            verify: "驗證",
                        },

                        deactivateOTP: {
                            title: "停用單次密碼",
                            description: "你確定要停用單次密碼嗎？",
                            password: "請輸入你的密碼確認：",
                        },

                        changePassword: {
                            title: "更改密碼",
                            current: "當前密碼",
                            new: "新密碼",
                            invalidPassword: "請輸入密碼。",
                            incorrectLogin: "登入資料不正確。請重新輸入。",
                            showPassword: "顯示密碼",
                            hidePassword: "隱藏密碼",
                        },
                    },
                    paymentDelivery: {
                        title: "付款和運送",
                        cards: "Cards",
                        addresses: "Addresses",
                    }
                },
                orders: {
                    title: "訂單歷史"
                }
            },

            product: {
                filter: {
                    showMe1: "帶我到",
                    showMe2: "",
                    categories: "分類",
                    sortBy1: "以",
                    sortBy2: "排序",
                },
                add_to_cart: "加入購物車",
                preorder_now: "接受預購",
                sold_out: "售罄",
                specification: "規格",
                material: "物料",
                accessories: "配件",
                share: "分享",
                productLimited1: "本產品僅適用於",
                productLimitedPunctuation: "、",
                productLimited2: "和",
                productLimited3: "。",
            },

            terms: {
                title: "條款及細則",

            },

            faq: {
                title: "常見問題",

            },

            contact: {
                title: "聯絡我們",
                regions: {
                    hongkong: "香港",
                    taiwan: "臺灣",
                    china: "中國"
                },
            },
        }
    }
}
