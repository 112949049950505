import Vue from "vue";
import VueRouter from "vue-router";

import stores from "../stores/index";

import Default from "../layout/Default";
import Login from "../layout/Login";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/",
        component: Default,
        beforeEnter (to, from, next) {
            if (stores.getters["admin/status"] !== "loggedIn") {
                const route = {
                    name: "login",
                    query: { "to": to.path, }
                };
                next(route);
                return;
            }
            next();
        },
        children: [
            {
                path: "/",
                component: () => import("../views/Overview.vue"),
            },
            {
                path: "/users",
                component: () => import("../views/Users.vue"),
                children: [
                    {
                        path: "",
                        redirect: "/users/user",
                    },
                    {
                        path: "admin",
                        name: "admins",
                        components: {
                            default: () => import("../components/Users/UserPlaceholder.vue"),
                            Users: () => import("../components/Users/AdminList.vue"),
                        },
                    },
                    {
                        path: "admin/:userId",
                        name: "admin-details",
                        components: {
                            default: () => import("../components/Users/UserDetails.vue"),
                            Users: () => import("../components/Users/AdminList.vue"),
                        },
                        props: {
                            default: true,
                            Users: true,
                        },
                    },
                    {
                        path: "user",
                        name: "users",
                        components: {
                            default: () => import("../components/Users/UserPlaceholder.vue"),
                            Users: () => import("../components/Users/UserList.vue"),
                        },
                    },
                    {
                        path: "user/:userId",
                        name: "user-details",
                        components: {
                            default: () => import("../components/Users/UserDetails.vue"),
                            Users: () => import("../components/Users/UserList.vue"),
                        },
                        props: {
                            default: true,
                            Users: true,
                        },
                    },
                ],
            },
            {
                path: "/groups",
                component: () => import("../views/Groups.vue"),
                children: [
                    {
                        path: "",
                        name: "groups",
                        component: () => import("../components/Groups/GroupPlaceholder.vue"),
                        props: true
                    },
                    {
                        path: ":groupId",
                        name: "group-details",
                        component: () => import("../components/Groups/GroupDetails.vue"),
                        props: true
                    },
                ],
            },
            {
                path: "/organizers",
                component: () => import("../views/Organizers.vue"),
                children: [
                    {
                        path: "",
                        name: "organizers",
                        component: () => import("../components/Organizers/OrganizerPlaceholder.vue"),
                    },
                    {
                        path: ":organizerId",
                        name: "organizer-details",
                        component: () => import("../components/Organizers/OrganizerDetails.vue"),
                        props: true
                    },

                ],
            },
            {
                path: "/reviews",
                component: () => import("../views/Reviews.vue"),
                children: [
                    {
                        path: "",
                        name: "reviews",
                        component: () => import("../components/Reviews/ReviewPlaceholderPanel.vue"),
                    },
                    {
                        path: ":reviewId",
                        name: "review-details",
                        component: () => import("../components/Reviews/ReviewDetails.vue"),
                        props: true
                    },
                ],
            },
            {
                path: "/chats",
                component: () => import("../views/Chats.vue"),
                children: [
                    {
                        path: "",
                        name: "chats",
                        component: () => import("../components/Chats/ChatsPlaceholder.vue"),
                    },
                    {
                        path: ":chatId",
                        name: "chat-details",
                        component: () => import("../components/Chats/ChatDetails.vue"),
                        props: true
                    },
                    {
                        path: ":chatId/members",
                        name: "chat-members",
                        component: () => import("../components/Chats/ChatMembers.vue"),
                        props: true
                    },
                ],
            },
            {
                path: "/events",
                component: () => import("../views/Activities.vue"),
                children: [
                    {
                        path: "",
                        name: "activities",
                        component: () => import("../components/Activities/ActivitiesPlaceholder.vue"),
                    },
                    {
                        path: ":activityId",
                        name: "activity-details",
                        component: () => import("../components/Activities/ActivityDetails.vue"),
                        props: true
                    },
                    {
                        path: ":activityId/participants",
                        name: "activity-participants",
                        component: () => import("../components/Activities/ActivityParticipants.vue"),
                        props: true
                    },
                ],
            },
            {
                path: "/promotions",
                component: () => import("../views/Promotions.vue"),
                children: [
                    {
                        path: "",
                        name: "promotions",
                        component: () => import("../components/Promotions/PromotionPlaceholder.vue"),
                    },
                    {
                        path: ":promotionId",
                        name: "promotion-details",
                        component: () => import("../components/Promotions/PromotionDetails.vue"),
                        props: true,
                    }
                ],
            },
            {
                path: "/settings",
                name: "settings",
                component: () => import("../views/Settings.vue"),
                children: [

                ],
            },
            {
                path: "/accounting",
                name: "accounting",
                component: () => import("../views/Accounting.vue"),
                children: [

                ],
            },
        ]
    },
    {
        path: "*",
        redirect: "/"
    },
];

const router = new VueRouter({
    "mode": "history",
    "base": process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    console.log(to, from);
    /*if (!to.params.region) {
        if (!stores.getters["layout/region"]) {
            stores.commit("layout/setRegion", "HKG");
        }
        const { name, params, query, } = to;
        next({
            "name": name,
            "params": { ...params, "region": stores.getters["layout/region"], },
            "query": query,
        });
        return;
    }
    stores.commit("layout/setRegion", to.params.region);*/
    next();
});

export default router;
