import localforage from "localforage";

import {
    apiUrl as rootUrl,
    apiVersion
} from "../../config";

export async function subscribeToChat (
    chatId
) {
    const token = await localforage.getItem("nakamian.admin-token");

    const evtSource = new EventSource(
        `${ rootUrl }/${ apiVersion }/chat/${ chatId }/live?token=${ token }`,
        {
            withCredentials: false
        }
    );

    evtSource.onmessage = (e) => {
        if (e.data !== "init") {
            console.log(JSON.parse(e.data));
        }
    };

    return evtSource;
}

export default subscribeToChat;
