const layout = {
    namespaced: true,
    state: {
        isSideBarOpen: false,
        isBasketBarOpen: false,

        offline: false,

        updateAvailable: false,
        updatedServiceWorker: null,

        region: "HKG",
    },
    getters: {
        isSideBarOpen ({ isSideBarOpen }) {
            return isSideBarOpen;
        },
        isUpdateAvailable ({ updateAvailable }) {
            return updateAvailable;
        },
        serviceWorker ({ updatedServiceWorker }) {
            return updatedServiceWorker;
        },
        isOnline ({ offline }) {
            return !offline;
        },
        region ({ region }) {
            return region;
        },
    },
    mutations: {
        toggleSideBar (state) {
            state.isSideBarOpen = !state.isSideBarOpen;
        },
        openSideBar (state) {
            state.isSideBarOpen = true;
        },
        closeSideBar (state) {
            state.isSideBarOpen = false;
        },
        updateAvailable (
            state,
            updatedServiceWorker
        ) {
            state.updateAvailable = true;
            state.updatedServiceWorker = updatedServiceWorker;
        },
        online (state) {
            state.offline = false;
        },
        offline (state) {
            state.offline = true;
        },
        setRegion (state, region) {
            state.region = region;
        },
    },
};

export default layout;
