import localforage from "localforage";
import { apiUrl, apiVersion, } from "../../config";

export async function getAdmins () {
    const token = await localforage.getItem("nakamian.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/admin?_id=true&email=true&role=true&verified=true`,
        {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${ token }`
            }
        }
    );

    if (response.ok) {
        const admins = await response.json();
        if (Array.isArray(admins)) {
            return admins;
        }
        throw "Get admin error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getAdmins;
