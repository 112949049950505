export default {
    "en": {
        message: {
            toasts: {
                offline: {
                    title: "Site Offline",
                    content: "The site is now offline. Information on this site may become stale. ",
                },

                update: {
                    title: "Update Available",
                    content: "Click to update 👉 ",
                    button: "Reload",
                },
            },

            navbar: {
                menu: "Menu",
                cart: "Cart"
            },

            sidebar: {
                register: "Register",
                login: "Login",
                logout: "Logout",
                account: "My Account",

                home: "Home",
                shop: "Shop",
                about: "About",
                contact: "Contact",

                delivery: "Delivery",
                faq: "FAQ",
                disclaimer: "Terms"
            },

            basketBar: {
                title: "Cart",
                checkout: "Checkout",
                deleteItem: "Delete",
                memberRequired: "Please login or register",
            },

            checkout: {
                title: "Checkout",
                delivery: "Delivery",
                subTotal: "Subtotal",
                total: "Total",
                myOrder: "My Order",
                products: "product(s)",
                items: "item(s)",
                contact: "Contact",
                "contact-email-notice": "An order confirmation will be sent to this email address. ",
                "contact-phone-notice": "We may contact you via this phone number.",
                "delivery-address": "Delivery Address",
                "delivery-method": "Delivery Method",
                "delivery-standard": "Standard shipping",
                paymentMethod: "Payment Method",
                saveAddress: "Save my address for faster checkouts in the future.",
                saveCard: "Save my Card for faster checkouts in the future.",
                "first-name": "First name",
                "last-name": "Last name",
                "email": "Email",
                "phone": "Phone",
                "address": "Address",
                "optional": "Optional",
                "country": "Country / Region",
                "postcode": "Postcode",
                "next": "Next",
                confirm: "Confirm",
                confirmOrder: "Confirm and Pay",
                "edit": "Edit",
                "add": "Add",
                "add-address": "Add a new address",
                "add-card": "Add a card",
                "add-another-card": "Use another card",
                "accept-tc-1": "I accept the ",
                "accept-tc-2": "terms and conditions",
                "accept-tc-3": ". ",
                thankYou: "Thank you!",
                paymentMethods: {
                    cards: "Payment Cards",
                    alipay: "AliPay",
                    wechatPay: "WeChat Pay",
                },
                paymentInProgress: "Payment in Progress...",
                paymentFailed: "Payment Failed. ",
                "retry-payment": "Retry? ",
                confirmEmailSent1: "An order confirmation has been sent to your email address: ",
                confirmEmailSent2: "",
            },

            login: {
                create_account: "Create Account",
                register: "Register",
                registerLine1: "with your email",
                login: "Login",
                loginLine1: "with your social accounts",
                loginLine2: "or with your email",
                accept_tc_line1: "I accept the ",
                accept_tc_line2: "terms and conditions",
                accept_tc_line3: ".",
                accept_tc: "",
                logout: "Logout",
                name: "Name",
                email: "Email Address",
                password: "Password",

                forgetPassword: {
                    title: "Forget your password? ",
                    content: "Please enter your email address used at registration. We will send an email containing instruction to reset your password to this address. ",
                    confirm: "Confirm",
                },

                verifyOTP: {
                    title: "Verify Token",
                    description: "Please enter the 6-digit token generated by your one-time password app below for verification: ",
                    verify: "Verify",
                },

                overlay: {
                    register: {
                        title: "Welcome On Board! ",
                        subtitle: "Register now to enjoy the most of Macott Station",
                    },
                    login: {
                        title: "Welcome back! ",
                        subtitle: "Login now with your credentials",
                    }
                },
            },

            account: {
                add: "Add",
                edit: "Edit",
                verify: "Verify",
                enable: "Enable",
                disable: "Disable",

                greetings: {
                    morning: "Good morning! ",
                    afternoon: "Good afternoon! ",
                    evening: "Good evening! ",
                },

                spending: {
                    type: {
                        purchase: "Purchase",
                        preorder: "Preorder",
                    },
                    totalLine1: "Here is your spending as of ",
                    totalLine2: ": ",
                },

                settings: {
                    title: "Settings",
                    details: {
                        title: "Account Details",
                        name: "Name",
                        email: "Email",
                        lang: "Language",

                        changeName: {
                            title: "Change Name",
                            new: "New Name",
                            password: "Please enter your password to confirm: ",
                        },

                        changeEmail: {
                            title: "Change Email",
                            new: "New Email",
                            password: "Please enter your password to confirm: ",
                            description: "A verification email has been delivered to your new email address. Until you verify your new email address, your account will remain unverified. ",
                        },
                    },
                    security: {
                        title: "Security",
                        password: "Password",

                        twoFactorAuth: "Two Factor Authentication",
                        enable2FA: "Enable Two Factor Authentication",
                        disable2FA: "Disable Two Factor Authentication",

                        activateOTP: {
                            "title": "One-time Password",
                            "subtitle": "Supports one-time password apps, such as Google Authenticator, Microsoft Authenticator, and Authy. ",
                            "description": "Scan the above QR Code with your preferred one-time password app or enter this code manually: ",
                            "verify": "Verify",
                        },

                        deactivateOTP: {
                            title: "Disable One-time Password",
                            description: "Are you sure you want to disable one-time password? ",
                            password: "Please enter your password to confirm: ",
                        },

                        changePassword: {
                            title: "Change Password",
                            current: "Current Password",
                            new: "New Password",
                            invalidPassword: "Please enter your password. ",
                            incorrectLogin: "Incorrect credentials. Please retry. ",
                            showPassword: "Show Password",
                            hidePassword: "Hide Password",
                        },
                    },
                    paymentDelivery: {
                        title: "Payment and Delivery",
                        cards: "Cards",
                        addresses: "Addresses",
                    }
                },
                orders: {
                    title: "Order History"
                }
            },

            product: {
                filter: {
                    showMe1: "Show me",
                    showMe2: "",
                    categories: "Categories",
                    sortBy1: "Sort by",
                    sortBy2: "",
                },
                add_to_cart: "Add to Cart",
                preorder_now: "Accept Pre-order",
                sold_out: "Sold Out",
                specification: "Specification",
                material: "Material",
                accessories: "Accessories",
                share: "Share",
                productLimited1: "This product is for ",
                productLimitedPunctuation: ", ",
                productLimited2: " and ",
                productLimited3: " only.",
            },

            terms: {
                title: "Terms and Conditions",

            },

            faq: {
                title: "Frequently Asked Questions",

            },

            contact: {
                title: "Contact",
                regions: {
                    hongkong: "Hong Kong",
                    taiwan: "Taiwan",
                    china: "China"
                },
            },
        }
    }
}
