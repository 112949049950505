import { apiUrl, apiVersion, } from "../../config";

import hexString from "./utilities/hexString";
import digestMessage from "./utilities/digestMessage";

export async function register ({ email, role, password }) {
    const hashed = await digestMessage(password);
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/admin/register`,
        {
            "method": "POST",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            "body": JSON.stringify(
                {
                    email,
                    role,
                    "password": hexString(hashed),
                    "mode": "email",
                    "twoFactorAuth": false
                }
            ),
        }
    );

    if (response.ok) {
        const user = await response.json();
        if (user) {
            return user;
        }
        throw "Register error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default register;
