import {
    format as dateFnsFormat,
    parseISO
} from "date-fns";

const dateFnsFormatter = (
    value,
    format,
    options = {}
) => {
    if (!value) {
        return "";
    }

    if (
        !(value instanceof Date) &&
        !["string", "number"].includes(typeof value)
    ) {
        return "";
    }

    if (typeof value === "string") {
        value = parseISO(value);
    }

    if (typeof value === "number") {
        value = new Date(value);
    }

    return dateFnsFormat(value, format, options);
};

let vueDateFnsFormatter = {
    install: function (Vue, options) {
        Vue.filter(
            "dateFnsFormatter",
            dateFnsFormatter
        );
    }
};

export { dateFnsFormatter };
export default vueDateFnsFormatter;
