import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./stores/index";
import i18n from "./init/vue-i18n";

Vue.config.productionTip = false;

import "vue-select/dist/vue-select.css";

import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

import "intersection-observer";
import "./registerServiceWorker";

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import "./init/font-awesome";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

import lineClamp from "vue-line-clamp";
Vue.use(lineClamp, {});

import vueDateFnsFormatter from "./plugins/date-fns-formatter/index";
Vue.use(vueDateFnsFormatter);

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount("#app");
