import Vue from "vue";
import locale2 from "locale2";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import enLangMessage from "../langs/en";
import zhHkLangMessage from "../langs/zh-hk";

let messages = Object.assign(
    {},
    enLangMessage,
    zhHkLangMessage,
);

console.log(locale2);

let detectedLang = "zh-hk";
if (localStorage.getItem("hkrecycle-mrb.user-lang")) {
    detectedLang = localStorage.getItem("hkrecycle-mrb.user-lang");
} else {
    if (["zh-cn"].includes(locale2)) {
        detectedLang = "zh-cn";
    }
    if (["en"].includes(locale2)) {
        detectedLang = "en";
    }
    if (["zh", "yue"].includes(locale2)) {
        detectedLang = "zh-hk";
    }
}

const i18n = new VueI18n({
    locale: detectedLang,
    fallbackLocale: "zh-hk",
    messages,
});

export default i18n;
