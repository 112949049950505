import {
    getGroups, addGroup, editGroup, deleteGroup,
    getCategories, addCategory, editCategory, deleteCategory,
} from "@/workers/group.worker";

const group = {
    namespaced: true,
    state: {
        groups: [],
        groupLoaded: 0,

        categories: [],
        categoryLoaded: 0,
    },
    getters: {
        items ({ groups }) {
            return groups;
        },
        itemTotal ({ groupLoaded }) {
            return groupLoaded;
        },
        itemById ({ groups }) {
            return id => groups.find(({ _id }) => _id === id);
        },
        itemsByCategory ({ groups }) {
            return id => groups.filter(({ category }) => category === id);
        },
        categories ({ categories }) {
            return categories;
        },
        categoriesByRegion ({ categories }) {
            return id => categories.filter(({ region }) => region === id);
        },
        categoryById ({ categories }) {
            return id => categories.find(({ _id }) => _id === id);
        },
    },
    mutations: {
        setGroups (state, { total, data }) {
            state.groups = data;
            state.groupLoaded = total;
        },
        addGroup (state, group) {
            state.groups.push(group);
        },
        editGroup (state, { groupId, group }) {
            state.groups.splice(state.groups.findIndex(({ _id }) => _id === groupId), 1, group);
            state.groups.sort((a, b) => a.order - b.order);
        },
        deleteGroup (state, groupId) {
            state.groups.splice(state.groups.findIndex(({ _id }) => _id === groupId), 1);
        },

        setCategories (state, { total, data }) {
            state.categories = data;
            state.categoryLoaded = total;
        },
        addCategory (state, category) {
            state.categories.push(category);
        },
        editCategory (state, { categoryId, category }) {
            state.categories.splice(state.categories.findIndex(({ _id }) => _id === categoryId), 1, category);
            state.categories.sort((a, b) => a.order - b.order);
        },
        deleteCategory (state, categoryId) {
            state.categories.splice(state.categories.findIndex(({ _id }) => _id === categoryId), 1);
        },
    },
    actions: {
        async load ({ commit }) {
            try {
                const [groups, categories] = await Promise.all([getGroups(), getCategories()]);
                commit("setGroups", groups);
                commit("setCategories", categories);
                return groups;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async add ({ commit }, group) {
            try {
                const result = await addGroup(group);
                commit("addGroup", result);
                return result;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async edit ({ commit }, { _id, ...group }) {
            try {
                const result = await editGroup(_id, group);
                commit("editGroup", { "groupId": _id, "group": result });
                return result;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async delete ({ commit }, groupId) {
            try {
                await deleteGroup(groupId);
                commit("deleteGroup", groupId);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },

        async addCategory ({ commit }, category) {
            try {
                const result = await addCategory(category);
                commit("addCategory", result);
                return result;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async editCategory ({ commit }, { _id, ...rest }) {
            console.log(rest);
            try {
                const result = await editCategory(_id, rest);
                commit("editCategory", { "categoryId": _id, "category": result });
                return result;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async deleteCategory ({ commit }, categoryId) {
            try {
                await deleteCategory(categoryId);
                commit("deleteCategory", categoryId);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
    },
};

export default group;
