import localforage from "localforage";

import {
    apiUrl as rootUrl,
    apiVersion
} from "../../config";

export async function sendMessage (
    chatId,
    message,
    options = {}
) {
    const token = await localforage.getItem("nakamian.admin-token");
    const response = await fetch(`${ rootUrl }/${ apiVersion }/message`, {
        "method": "POST",
        "mode": "cors",
        "headers": {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${ token }`
        },
        "body": JSON.stringify({ chatId, message }),
    });

    if (response.ok) {
        /*let message = await response.json();
        if (message) {
            return message;
        }
        throw "MESSAGE POST error";*/

        return true;
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default sendMessage;
