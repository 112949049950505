import Vue from "vue";

import {
    getEvaluations, getEvaluationsByOrganizer,
    editEvaluation, approveEvaluation, rejectEvaluation,
} from "@/workers/evaluation.worker";

const evaluation = {
    namespaced: true,
    state: {
        evaluations: [],
        evaluationTotal: 0,
        evaluationLoaded: 0,
        evaluationsByOrganizerMap: {},
        evaluationLoadedByOrganizerMap: {},
    },
    getters: {
        items ({ evaluations }) {
            return evaluations;
        },
        total ({ evaluationTotal }) {
            return evaluationTotal;
        },
        itemsByOrganizer ({ evaluationsByOrganizerMap }) {
            return organizerId => evaluationsByOrganizerMap[organizerId] ?? [];
        },
        itemById ({ evaluations }) {
            return id => evaluations.find(({ _id }) => _id === id);
        },
    },
    mutations: {
        setEvaluations (state, { total, data }) {
            state.evaluations = data;
            state.evaluationLoaded = data.length;
            state.evaluationTotal = total;
        },
        appendEvaluations (state, { total, data }) {
            state.evaluations = state.evaluations.concat(data);
            state.evaluationLoaded = state.evaluations.length;
            state.evaluationTotal = total;
        },
        setEvaluationsByOrganizer (state, { organizerId, total, data }) {
            Vue.set(state.evaluationsByOrganizerMap, organizerId, data);
            Vue.set(state.evaluationLoadedByOrganizerMap, organizerId, total);
        },
        editEvaluation (state, evaluation) {
            state.evaluations.splice(
                state.evaluations.findIndex(({ _id }) => _id === evaluation._id), 1, evaluation
            );
            if (state.evaluationsByOrganizerMap[evaluation.answers.organizer]) {
                const temp = state.evaluationsByOrganizerMap[evaluation.answers.organizer].slice();
                temp.splice(temp.findIndex(({ _id }) => _id === evaluation._id), 1, evaluation);
                Vue.set(
                    state.evaluationsByOrganizerMap,
                    evaluation.answers.organizer,
                    temp
                );
            }
        },
    },
    actions: {
        async load ({ commit }) {
            try {
                const evaluations = await getEvaluations();
                commit("setEvaluations", evaluations);
                return evaluations;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async loadMore ({ commit, state: { evaluationLoaded, evaluationTotal } }, count = 50) {
            if (evaluationLoaded !== 0 && evaluationLoaded >= evaluationTotal) {
                return;
            }
            try {
                const evaluations = await getEvaluations(evaluationLoaded, count);
                commit("appendEvaluations", evaluations);
                return evaluations;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async loadByOrganizer ({ commit }, organizerId) {
            try {
                const evaluations = await getEvaluationsByOrganizer(organizerId);
                commit("setEvaluationsByOrganizer", { organizerId, ...evaluations });
                return evaluations;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async edit ({ commit }, { _id, ...rest }) {
            try {
                const evaluation = await editEvaluation(_id, rest);
                commit("editEvaluation", evaluation);
                return evaluation;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async approve ({ commit }, evaluationId) {
            try {
                const evaluation = await approveEvaluation(evaluationId);
                commit("editEvaluation", evaluation);
                return evaluation;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async reject ({ commit }, evaluationId) {
            try {
                const evaluation = await rejectEvaluation(evaluationId);
                commit("editEvaluation", evaluation);
                return evaluation;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
    },
};

export default evaluation;
