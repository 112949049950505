<template>
    <div id="app"
         class="bg-info">
        <router-view></router-view>
        <b-toast no-auto-hide
                 solid
                 v-model="isUpdateAvailable">
            <template v-slot:toast-title>
                <strong>
                    Update available
                </strong>
            </template>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    Click to update👉
                </div>
                <div class="d-flex align-items-center">
                    <button class="btn btn-sm btn-primary px-4"
                            v-on:click="updateApp">
                        Refresh
                    </button>
                </div>
            </div>
        </b-toast>
        <b-toast no-auto-hide
                 solid
                 v-model="isOffline">
            <template v-slot:toast-title>
                <strong>
                    Site offline
                </strong>
            </template>
            The site is now offline. Information may become stale.
        </b-toast>
    </div>
</template>

<script>
import { BToast, } from "bootstrap-vue";
export default {
    metaInfo: {
        titleTemplate: "%s | Messagify",
    },
    components: {
        BToast,
    },
    computed: {
        user () {
            return this.$store.getters["admin/info"];
        },
        userStatus () {
            return this.$store.getters["admin/status"];
        },
        isOffline () {
            return !this.$store.getters["layout/isOnline"];
        },
        isUpdateAvailable () {
            return this.$store.getters["layout/isUpdateAvailable"];
        },
        serviceWorker () {
            return this.$store.getters["layout/serviceWorker"];
        },
    },
    methods: {
        updateApp () {
            if (navigator.serviceWorker) {
                if (this.serviceWorker) {
                    let refreshing = false;
                    navigator.serviceWorker.addEventListener(
                        "controllerchange",
                        () => {
                            if (refreshing) {
                                return;
                            }
                            window.location.reload();
                            refreshing = true;
                        }
                    );
                    this.serviceWorker.postMessage({ type: "SKIP_WAITING", });
                }
            }
        },
    },
    async created () {
        try {
            await this.$store.dispatch("admin/loadToken");
        } catch (e) {
            return;
        }

        let redirect = "/";
        if (this.$route.query.to) {
            redirect = this.$route.query.to;
        }
        this.$router.push({
            "path": redirect
        });
    },
    mounted () {
        window.addEventListener("online", () => this.$store.commit("layout/online"));
        window.addEventListener("offline", () => this.$store.commit("layout/offline"));
    },
    watch: {
        async userStatus (newVal) {
            if (newVal === "loggedIn") {
                this.$store.dispatch("admin/loadAdmins");

                this.$store.dispatch("users/loadUsers");

                this.$store.dispatch("category/loadCategories");

                this.$store.dispatch("event/loadEvents");
                this.$store.dispatch("promotion/load");

                this.$store.dispatch("group/load");
                this.$store.dispatch("region/load");

                this.$store.dispatch("whatsapp/loadChats");
            }
        }
    }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index";

html,
body {
    background-color: rgba(245, 245, 245, 1);

    font-family:
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        "Microsoft JhengHei",
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow-x: hidden;

    @include media-breakpoint-down(md) {
        overflow-y: hidden;
    }
}

$flex-values: 1 2 3 4;
@each $value in $flex-values {
    .flex-#{$value} {
        flex: $value !important;
    }
}

.rounded-xl {
    border-radius: 1rem !important;
}

.font-family-pacifico {
    font-family: "Pacifico", cursive;
}

.btn {
    &.btn-loading {
        position: relative;
        pointer-events: none;
        color: transparent !important;

        &:after {
            position: absolute;
            left: calc(50% - (1em / 2));
            top: calc(50% - (1em / 2));

            display: block;
            height: 1em;
            width: 1em;

            border: 2px solid;
            border-color: rgba(219, 219, 219, 1);
            border-radius: 290486px;
            border-right-color: transparent;
            border-top-color: transparent;

            content: "";

            animation: spinAround .5s infinite linear;
        }
    }
}

@keyframes spinAround {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}
</style>
