import {
    library
} from "@fortawesome/fontawesome-svg-core";

import {
    faHome as faHomeSolid,
    faUsers as faUsersSolid,
    faUserFriends as faUserFriendsSolid,
    faUsersCrown as faUsersCrownSolid,
    faComments as faCommentsSolid,
    faMobile as faMobileSolid,
    faAd as faAdSolid,
    faCalendarStar as faCalendarStarSolid,
    faSignOutAlt as faSignOutAltSolid,

    faBars as faBarsSolid,
    faImages as faImagesSolid,
    faVideo as faVideoSolid,
    faMicrophone as faMicrophoneSolid,
    faPlusSquare as faPlusSquareSolid,
    faPaperPlane as faPaperPlaneSolid,
    faCaretDown as faCaretDownSolid,
    faFilter as faFilterSolid,

    faPencilAlt as faPencilAltSolid,
    faTrashAlt as faTrashAltSolid,
    faCheckCircle as faCheckCircleSolid,
    faTimesCircle as faTimesCircleSolid,
    faArrowLeft as faArrowLeftSolid,
    faTimes as faTimesSolid,

    faCogs as faCogsSolid,
    faMapMarkerAlt as faMapMarkerAltSolid,
    faSpinner as faSpinnerSolid,

    faCalendarTimes as faCalendarTimesSolid,
    faGlobe as faGlobeSolid,

    faPhone as faPhoneSolid,
    faVenusMars as faVenusMarsSolid,
    faVenus as faVenusSolid,
    faMars as faMarsSolid,
    faBirthdayCake as faBirthdayCakeSolid,
    faPeopleArrows as faPeopleArrowsSolid,

    faCommentAltSmile as faCommentAltSmileSolid,
    faShieldCheck as faShieldCheckSolid,
    faClipboardListCheck as faClipboardListCheckSolid,
    faCommentAltCheck as faCommentAltCheckSolid,
    faCommentAltTimes as faCommentAltTimesSolid,
    faCommentSmile as faCommentSmileSolid,

    faTags as faTagsSolid,
    faXmark as faXmarkSolid,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faLongArrowRight as faLongArrowRightRegular,
    faChevronRight as faChevronRightRegular,
    faChevronLeft as faChevronLeftRegular,
    faChevronDown as faChevronDownRegular,

    faBold as faBoldRegular,
    faItalic as faItalicRegular,
    faStrikethrough as faStrikethroughRegular,
    faUnderline as faUnderlineRegular,
    faParagraph as faParagraphRegular,
    faH1 as faH1Regular,
    faH2 as faH2Regular,
    faH3 as faH3Regular,
    faListUl as faListUlRegular,
    faListOl as faListOlRegular,
    faRedo as faRedoRegular,
    faUndo as faUndoRegular,
} from "@fortawesome/pro-regular-svg-icons";

import {

} from "@fortawesome/pro-light-svg-icons";

import {
    faHome as faHomeDuotone,
    faUsers as faUsersDuotone,
    faUserFriends as faUserFriendsDuotone,
    faComments as faCommentsDuotone,
    faMobile as faMobileDuotone,
    faAd as faAdDuotone,
    faCalendarStar as faCalendarStarDuotone,
    faSignOutAlt as faSignOutAltDuotone,
    faUserGroupCrown as faUserGroupCrownDuotone,
    faUserCrown as faUserCrownDuotone,
    faUser as faUserDuotone,
    faCogs as faCogsDuotone,
    faGauge as faGaugeDuotone,

    faAt as faAtDuotone,
    faPhone as faPhoneDuotone,
} from "@fortawesome/pro-duotone-svg-icons";

import {
    faWhatsapp,
    faTelegram,
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faHomeSolid,
    faUsersSolid,
    faUserFriendsSolid,
    faUsersCrownSolid,
    faCommentsSolid,
    faMobileSolid,
    faAdSolid,
    faSignOutAltSolid,
    faBarsSolid,
    faPlusSquareSolid,
    faImagesSolid,
    faVideoSolid,
    faMicrophoneSolid,
    faPaperPlaneSolid,
    faCaretDownSolid,
    faFilterSolid,

    faPencilAltSolid,
    faTrashAltSolid,
    faCheckCircleSolid,
    faTimesCircleSolid,
    faArrowLeftSolid,
    faTimesSolid,

    faCogsSolid,
    faMapMarkerAltSolid,
    faSpinnerSolid,

    faCalendarTimesSolid,
    faGlobeSolid,

    faPhoneSolid,
    faVenusMarsSolid,
    faVenusSolid,
    faMarsSolid,
    faBirthdayCakeSolid,

    faCalendarStarSolid,
    faCommentAltSmileSolid,
    faShieldCheckSolid,
    faClipboardListCheckSolid,
    faCommentAltCheckSolid,
    faCommentAltTimesSolid,
    faCommentSmileSolid,
    faPeopleArrowsSolid,

    faTagsSolid,
    faXmarkSolid,

    faLongArrowRightRegular,
    faChevronRightRegular,
    faChevronLeftRegular,
    faChevronDownRegular,

    faBoldRegular,
    faItalicRegular,
    faStrikethroughRegular,
    faUnderlineRegular,
    faParagraphRegular,
    faH1Regular,
    faH2Regular,
    faH3Regular,
    faListUlRegular,
    faListOlRegular,
    faRedoRegular,
    faUndoRegular,

    faHomeDuotone,
    faUsersDuotone,
    faUserFriendsDuotone,
    faCommentsDuotone,
    faMobileDuotone,
    faAdDuotone,
    faCalendarStarDuotone,
    faSignOutAltDuotone,
    faUserGroupCrownDuotone,
    faUserCrownDuotone,
    faUserDuotone,
    faCogsDuotone,
    faGaugeDuotone,

    faAtDuotone,
    faPhoneDuotone,

    faWhatsapp,
    faTelegram,
);
