<template>
    <div class="row no-gutters flex-nowrap">
        <side-bar></side-bar>
        <main-panel class="col"></main-panel>
    </div>
</template>

<script>
export default {
    name: "Default",
    components: {
        SideBar: () => import("@/components/layout/SideBar"),
        MainPanel: () => import("./MainPanel"),
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
