import authToken from "../api/admin/authToken";

import register from "../api/admin/register";
import login from "../api/admin/login";
import logout from "../api/admin/logout";

import getAdmins from "../api/admin/getAdmins";

const admin = {
    namespaced: true,
    state: {
        status: "loggedOut",
        message: "",
        loggedIn: false,
        loginFailed: false,
        registerFailed: false,
        token: "",
        user: {},

        admins: [],
    },
    getters: {
        info ({ user }) {
            return user;
        },
        status ({ status }) {
            return status;
        },
    },
    mutations: {
        resetStatus (state) {
            state.status = "loggedOut";
        },
        setLoggingIn (state) {
            state.status = "loggingIn";
        },
        setLoginSuccess (state) {
            state.status = "loggedIn";
        },
        setLoginFailed (state) {
            state.status = "loginFailed";
        },
        setRegistering (state) {
            state.status = "registering";
        },
        setRegisterFailed (state) {
            state.status = "registerFailed";
        },
        setMessage (state, message) {
            state.message = message;
        },
        unsetMessage (state) {
            state.message = "";
        },
        setUser (state, user) {
            let temp = Object.assign({}, user);
            delete temp.__v;
            state.user = temp;
        },
        setAdmins (state, admins) {
            state.admins = admins;
        }
    },
    actions: {
        async loadToken ({ commit, },) {
            commit("setLoggingIn", true);
            let user;
            try {
                user = await authToken();
            } catch (e) {
                commit("setLoginFailed", true);
                console.error(e);
                throw e;
            }
            commit("setMessage", "");
            commit("setUser", user);
            commit("setLoginSuccess", true);
        },

        async loadAdmins ({ commit, },) {
            let admins;
            try {
                admins = await getAdmins();
            } catch (e) {
                console.error(e);
                throw e;
            }
            commit("setAdmins", admins);
            return admins;
        },

        async register ({ commit, }, payload) {
            commit("setRegistering");
            commit("setMessage", "");
            let user;
            try {
                user = await register(payload);
            } catch (e) {
                console.error(e);
                commit("setRegisterFailed");
                commit("setMessage", e.reason);
                throw e;
            }
            commit("setLoggingIn");
            let token;
            try {
                token = await login(payload);
            } catch (e) {
                console.error(e);
                commit("setLoginFailed");
                commit("setMessage", e.reason);
                throw e;
            }
            user = await authToken(token);
            commit("setUser", user);
            commit("setLoginSuccess");
            return user;
        },
        async login ({ commit }, payload) {
            commit("setLoggingIn");
            commit("setMessage", "");
            let token;
            try {
                token = await login(payload);
            } catch (e) {
                console.error(e);
                commit("setLoginFailed");
                commit("setMessage", e.reason);
                throw e;
            }
            const user = await authToken(token);
            commit("setUser", user);
            commit("setLoginSuccess");
            return user;
        },
        async logout ({ commit, }) {
            await logout();
            commit("setMessage", "");
            commit("resetStatus");
        },
        setMessage ({ commit, }, message) {
            commit("setMessage", message);
        },
        unsetMessage ({ commit, }) {
            commit("unsetMessage");
        }
    }
};

export default admin;
