import localforage from "localforage";
import { apiUrl, apiVersion } from "../../config";

export async function authToken (
    tokenToBeTested
) {
    const token = tokenToBeTested ?? await localforage.getItem("nakamian.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/admin/authenticate`,
        {
            "method": "GET",
            "headers": {
                "Authorization": `Bearer ${ token }`
            }
        }
    );

    if (response.ok) {
        const user = await response.json();
        if (user) {
            return user;
        }
        await localforage.setItem("nakamian.admin-token", token);
        throw "USER authToken error";
    }

    if (response.status >= 400) {
        await localforage.setItem("nakamian.admin-token", "");
        throw await response.text();
    }

    throw "Internal server error";
}

export default authToken;
