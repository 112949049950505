import { getUsers, } from "../workers/users.worker";

import addUser from "../api/users/addUser";
import editUser from "../api/users/editUser";
import deleteUser from "../api/users/deleteUser";

const Users = {
    namespaced: true,
    state: {
        users: [],
        usersInterval: null,
    },
    getters: {
        items ({ users }) {
            return users;
        },
        itemsByRole ({ users }) {
            return target => {
                return users.filter(({ role }) => role === target);
            }
        },
        itemById ({ users }) {
            return id => {
                return users.find(({ _id }) => _id === id)
            }
        },
    },
    mutations: {
        setUsers (state, users) {
            state.users = users;
        },
        setUsersInterval (state, intervalId) {
            state.usersInterval = intervalId;
        },
        addUser (state, user) {
            state.users.push(user);
        },
        editUser (state, newUser) {
            state.users.splice(
                state.users.findIndex(user => user._id === newUser._id),
                1,
                newUser
            );
        },
        deleteUser (state, userId) {
            state.users.splice(state.users.findIndex(user => user._id === userId), 1);
        },
    },
    actions: {
        async loadUsers ({ commit }) {
            try {
                const users = await getUsers();
                commit("setUsers", users);
                return users;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async refreshUsersRegularly ({ commit }, duration = 300000) {
            const intervalId = setInterval(
                async () => {
                    try {
                        const users = await getUsers();
                        commit("setUsers", users);
                    } catch (e) {
                        console.error(e);
                        throw e;
                    }
                },
                duration
            );
            commit("setUsersInterval", intervalId);
            return intervalId;
        },
        async stopRefreshingUsers ({ commit, state }) {
            clearInterval(state.intervalId);
            commit("setUsersInterval", null);
        },
        async addUser ({ commit }, newUser) {
            try {
                const user = await addUser(newUser);
                commit("addUser", user);
                return user;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async editUser ({ commit }, updatedUser) {
            try {
                const user = await editUser(updatedUser);
                commit("editUser", user);
                return user;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async deleteUser ({ commit }, userId) {
            try {
                await deleteUser(userId);
                commit("deleteUser", userId);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
    }
};

export default Users;
