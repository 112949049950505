//const baseUrl = "https://dashboard.nakama.club";
export const baseUrl = "https://api.nakamian.group/dashboard";
//const apiUrl = "https://api.nakama.club";
export const apiUrl = "https://api.nakamian.group";
export const apiVersion = "v1.0";
export const apiNewVersion = "v1.1";
export const apiVersion2 = "v2.0";

export const CountryCodes = [
    {
        text: "+852 🇭🇰香港",
        value: "852",
        locale: "zh-HK",
        minlength: 8,
        maxlength: 8,
    },
    {
        text: "+853 🇲🇴澳門",
        value: "853",
        locale: "zh-HK",
        minlength: 8,
        maxlength: 8,
    },
    {
        text: "+886 🇹🇼臺灣",
        value: "886",
        locale: "zh-TW",
        minlength: 9,
        maxlength: 10,
    },
    {
        text: "+81 🇯🇵日本",
        value: "81",
        locale: "ja-JP",
        minlength: 10,
        maxlength: 10,
    },
    {
        text: "+44 🇬🇧英國",
        value: "44",
        locale: "en-GB",
        minlength: 10,
        maxlength: 11,
    },
    {
        text: "+1 🇺🇸美國",
        value: "1",
        locale: "en-US",
        minlength: 10,
        maxlength: 10,
    },
    {
        text: "+1 🇨🇦加拿大",
        value: "1",
        locale: "en-US",
        minlength: 10,
        maxlength: 10,
    },
    {
        text: "+61 🇦🇺澳洲",
        value: "61",
        locale: "en-AU",
        minlength: 10,
        maxlength: 10,
    },
    {
        text: "+64 🇳🇿紐西蘭",
        value: "64",
        locale: "en-NZ",
        minlength: 9,
        maxlength: 9,
    },
];

export const AgeGroups = [
    {
        value: "<=15",
        text: "15歲或以下",
    },
    {
        value: "16-20",
        text: "16-20歲",
    },
    {
        value: "21-25",
        text: "21-25歲",
    },
    {
        value: "26-30",
        text: "26-30歲",
    },
    {
        value: "31-35",
        text: "31-35歲",
    },
    {
        value: "36-40",
        text: "36-40歲",
    },
    {
        value: ">=41",
        text: "41歲或以上",
    }
];
