<template>
    <div class="login-wrapper d-flex justify-content-center align-items-center">
        <form class="login-form bg-white border rounded-xl shadow-lg p-3"
              v-on:submit.prevent.stop="login">
            <img class="logo mb-3"
                 src="../assets/logo.svg"
                 alt="logo">
            <h1 class="font-family-pacifico mb-0">
                Messagify
            </h1>
            <h2 class="font-weight-bolder mb-4">
                welcomes you📣
            </h2>
            <div class="mb-5">
                <template v-if="userStatus === 'loggingIn'">
                    <div class="text-center mt-2 mb-1">
                        <b-spinner variant="dark"
                                   style="height: 5rem; width: 5rem; ">
                        </b-spinner>
                    </div>
                </template>
                <template v-else>
                    <b-form-input type="text"
                                  id="inputUsername"
                                  class="shadow-sm bg-light"
                                  placeholder="👤 Username"
                                  required
                                  autofocus
                                  v-bind:state="isValid"
                                  v-model="username"
                                  v-on:focus="isValid = isValid === false ? null : isValid">
                    </b-form-input>
                    <b-form-input type="password"
                                  id="inputPassword"
                                  class="shadow-sm bg-light"
                                  placeholder="🔑 Password"
                                  required
                                  v-bind:state="isValid"
                                  v-model="password"
                                  v-on:focus="isValid = isValid === false ? null : isValid">
                    </b-form-input>
                    <div class="invalid-feedback"
                         v-if="isValid === false">
                        Incorrect credentials.
                    </div>
                </template>
            </div>
            <div class="mb-5">
                <button class="btn btn-dark btn-block font-weight-bold border shadow-sm"
                        type="submit"
                        v-bind:class="{ 'btn-loading': isLoading || userStatus === 'loggingIn' }">
                    Login
                </button>
            </div>
            <p class="text-muted mb-0">
                Messagify &copy; 2021 {{ new Date().getFullYear() > 2021 ? `-${ new Date().getFullYear() }` : "" }} OblonDATA.IO Limited
            </p>
        </form>
    </div>
</template>

<script>
import { BFormInput, BSpinner, } from "bootstrap-vue";

export default {
    name: "Login",
    metaInfo () {
        return {
            "title": "Login",
        };
    },
    components: {
        BFormInput, BSpinner,
    },
    data () {
        return {
            isLoading: false,
            isValid: null,

            username: "",
            password: "",
        };
    },
    computed: {
        userStatus () {
            return this.$store.getters["admin/status"];
        },
    },
    methods: {
        reset () {
            this.username = "";
            this.password = "";
        },
        async login () {
            try {
                this.isLoading = true;
                const { username, password, } = this;
                await this.$store.dispatch("admin/login", { "email": username, password });
                this.isValid = true;
                this.$router.push("/");
            } catch (e) {
                console.error(e);
                this.isValid = false;
            } finally {
                this.isLoading = false;
                this.reset();
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.login-wrapper {
    height: 100vh;
}

.login-form {
    width: 100%;
    max-width: 380px;
}

.logo {
    height: 95px;
    width: auto;
}

.form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;

    &:focus {
        z-index: 2;
    }
}

input[type="text"] {
    border-color: #dee2e6;
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

input[type="password"] {
    border-color: #dee2e6;
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
</style>
