import { getPromotions, addPromotion, editPromotion, deletePromotion, } from "@/workers/promotion.worker";

const promotion = {
    namespaced: true,
    state: {
        promotions: [],
        pagination: { total: 0, },
    },
    getters: {
        items ({ promotions }) {
            return promotions;
        },
        itemById ({ promotions }) {
            return (id) => promotions.find(({ _id }) => _id === id);
        },
    },
    mutations: {
        setPromotions (state, { total, data }) {
            state.promotions = data;
            state.pagination.total = total;
        },
        addPromotion (state, promotion) {
            state.promotions.push(promotion);
        },
        editPromotion (state, { promotionId, promotion }) {
            state.promotions.splice(
                state.promotions.findIndex(({ _id }) => _id === promotionId),
                1,
                promotion
            );
            state.promotions.sort((a, b) => a.order - b.order);
        },
        deletePromotion (state, promotionId) {
            state.promotions
                .splice(
                    state.promotions.findIndex(({ _id }) => _id === promotionId),
                    1
                );
        },
    },
    actions: {
        async load ({ commit }) {
            try {
                const promotions = await getPromotions();
                commit("setPromotions", promotions);
                return promotions;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async add ({ commit }, promotion) {
            try {
                const result = await addPromotion(promotion);
                commit("addPromotion", result);
                return result;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async edit ({ commit }, { _id, ...promotion }) {
            try {
                const result = await editPromotion(_id, promotion);
                commit("editPromotion", { "promotionId": _id, "promotion": result });
                return result;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async delete ({ commit }, promotionId) {
            try {
                await deletePromotion(promotionId);
                commit("deletePromotion", promotionId);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
    },
};

export default promotion;
