import localforage from "localforage";
import { apiUrl, apiVersion, } from "../../config";

import hexString from "./utilities/hexString";
import digestMessage from "./utilities/digestMessage";

export async function login ({ email, password, }) {
    const hashed = await digestMessage(password);
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/admin/login`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
                password: hexString(hashed),
                mode: "email"
            }),
        }
    );

    if (response.ok) {
        const incomingToken = await response.text();
        if (incomingToken) {
            await localforage.setItem("nakamian.admin-token", incomingToken);
            return incomingToken;
        }
        throw "Login error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw ("Internal server error");
}

export default login;
