import { getCategories, addCategory, editCategory, deleteCategory, } from "@/workers/category.worker";

const category = {
    namespaced: true,
    state: {
        isLoading: false,
        categories: [],
        loaded: 0,
        total: 0,
    },
    getters: {
        isLoading ({ isLoading }) {
            return isLoading;
        },
        total ({ total }) {
            return total;
        },
        items ({ categories }) {
            return categories;
        },
        itemById ({ categories }) {
            return categoryId => categories.filter(({ _id }) => _id === categoryId);
        },
        itemsByTypeEvent ({ categories }) {
            return categories.filter(
                ({ type }) => type === "event"
            );
        },
        itemsByTypePromotion ({ categories }) {
            return categories.filter(
                ({ type }) => type === "promotion"
            );
        },
    },
    mutations: {
        setLoading (state, status) {
            state.isLoading = status;
        },
        setCategories (state, categories) {
            state.categories = categories;
        },
        appendCategories (state, categories) {
            state.categories = state.categories.concat(categories);
        },
        setTotal (state, total) {
            state.total = total;
        },
        setLoaded (state, loaded) {
            state.loaded = loaded;
        },
        addCategory (state, category) {
            state.categories.push(category);
        },
        editCategory (state, category) {
            state.categories.splice(
                state.categories.findIndex(
                    ({ _id }) => _id === category._id
                ),
                1,
                category,
            );
        },
        deleteCategory (state, categoryId) {
            state.categories.splice(
                state.categories.findIndex(
                    ({ _id }) => _id === categoryId
                ),
                1,
            );
        },
    },
    actions: {
        async loadCategories (
            { commit },
            { start, count } = { start: 0, count: 100 }
        ) {
            try {
                commit("setLoading", true);
                const { data, total, } = await getCategories(start, count);
                commit("setCategories", data);
                commit("setTotal", total);
                commit("setLoaded", data.length);
                commit("setLoading", false);
                return data;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async loadMoreCategories (
            { commit, state: { loaded, total } },
            count = 50
        ) {
            if (loaded >= total) {
                return;
            }
            try {
                commit("setLoading", true);
                const { data, total, } = await getCategories(loaded, count);
                commit("appendCategories", data);
                commit("setTotal", total);
                commit("setLoaded", loaded + data.length);
                commit("setLoading", false);
                return data;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async addCategory (
            { commit, state: { total, loaded } },
            newCategory,
        ) {
            try {
                commit("setLoading", true);
                const category = await addCategory(newCategory,);
                commit("addCategory", category);
                commit("setTotal", total + 1);
                commit("setLoaded", loaded + 1);
                commit("setLoading", false);
                return category;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async editCategory (
            { commit },
            { _id, ...rest },
        ) {
            try {
                commit("setLoading", true);
                const category = await editCategory(_id, rest,);
                commit("editCategory", category);
                commit("setLoading", false);
                return category;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async deleteCategory (
            { commit, state: { total, loaded } },
            categoryId,
        ) {
            try {
                await deleteCategory(categoryId);
                commit("deleteCategory", categoryId);
                commit("setTotal", total - 1);
                commit("setLoaded", loaded - 1);
                commit("setLoading", false);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
    },
}

export default category;
