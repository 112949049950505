import localforage from "localforage";
import { apiUrl, apiVersion } from "@/config";
export async function deleteUser (userId) {
    const token = await localforage.getItem("nakamian.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/user/${ userId }`,
        {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
        }
    );
    if (response.ok) {
        return;
    }
    if (response.status >= 400) {
        throw await response.text();
    }
    throw ("Internal server error");
}
export default deleteUser;
