import localforage from "localforage";

import {
    apiUrl as rootUrl,
    apiVersion
} from "../../config";

export async function getAccountById (id) {
    const token = await localforage.getItem("nakamian.admin-token");
    const response = await fetch(
        `${ rootUrl }/${ apiVersion }/contact/${ id }`,
        {
            "method": "GET",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
        }
    );

    if (response.ok) {
        const account = await response.json();
        if (account) {
            return account;
        }
        throw "WHATSAPP ACCOUNT GET error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getAccountById;
