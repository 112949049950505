import { getActivities, addActivity, editActivity, deleteActivity, } from "@/workers/activity.worker";

const activity = {
    namespaced: true,
    state: {
        activities: [],
    },
    getters: {
        items ({ activities }) {
            return activities;
        },
        itemById ({ activities }) {
            return id => activities.find(({ _id }) => _id === id);
        },
        itemsByCategory ({ activities }) {
            return categoryId => activities.filter(({ category }) => category?._id ?? category === categoryId);
        },
        itemsByOrganizer ({ activities }) {
            return organizerId => activities.filter(({ createdBy }) => createdBy === organizerId);
        },
    },
    mutations: {
        setActivities (state, activities) {
            state.activities = activities;
        },
        addActivity (state, event) {
            state.activities.push(event);
        },
        editActivity (state, activity) {
            state.activities.splice(state.activities.findIndex(({ _id }) => _id === activity._id), 1, activity);
        },
        deleteActivity (state, activityId) {
            state.activities.splice(state.activities.findIndex(({ _id }) => _id === activityId), 1);
        },
    },
    actions: {
        async loadEvents ({ commit }) {
            try {
                const activities = await getActivities();
                commit("setActivities", activities);
                return activities;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async addEvent ({ commit }, event) {
            try {
                const result = await addActivity(event);
                commit("addActivity", result);
                return result;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async editEvent ({ commit }, event) {
            try {
                const result = await editActivity(event);
                commit("editActivity", result);
                return result;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async deleteActivity ({ commit }, activityId) {
            try {
                await deleteActivity(activityId);
                commit("deleteActivity", activityId);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
    }
};

export default activity;
