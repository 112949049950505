import { getChats, } from "@/workers/whatsapp.worker";
import subscribeToChat from "@/api/whatsapp/subscribeToChat";
import sendMessage from "../api/whatsapp/sendMessage";
import getAccountById from "../api/whatsapp/getAccountById";

import Fuse from "fuse.js";

import Vue from "vue";

const Whatsapp = {
    namespaced: true,
    state: {
        chats: [],
        streams: {},
        accountsByUser: {},
    },
    getters: {
        chats ({ chats }) {
            return chats;
        },
        chatById ({ chats }) {
            return id => chats.find(({ _id }) => _id === id);
        },
        fuse ({ chats }) {
            return new Fuse(
                chats,
                {
                    "keys": [
                        {
                            "name": "name",
                            "weight": 4,
                        },
                        {
                            "name": "vendorId",
                            "weight": 2,
                        },
                        {
                            "name": "desc",
                            "weight": 1,
                        },
                    ],
                }
            );
        },
    },
    mutations: {
        setChats (state, chats) {
            state.chats = chats;
        },

        subscribeToChat (state, { chatId, stream }) {
            state.streams[chatId] = stream;
        },

        addMessage (state, { chatId, message }) {
            let target = state.chats.find(
                chat => chat._id === chatId
            );
            if (target) {
                target.latestMessages.push(message);
            }
        },

        setBrands (state, brands) {
            state.brands = brands;
        },

        addDevice (state, device) {
            state.devices.push(device);
        },

        editDevice (state, updatedDevice) {
            state.devices.splice(state.devices.findIndex(device => device._id === updatedDevice._id), 1, updatedDevice);
        },

        deleteDevice (state, deviceId) {
            state.devices.splice(state.devices.findIndex(device => device._id === deviceId), 1);
        },

        setAccountByUserId (
            state,
            { userId, account }
        ) {
            Vue.set(state.accountsByUser, userId, account);
        },
    },
    actions: {
        async loadChats (
            context,
        ) {
            let chats;
            try {
                chats = await getChats();
            } catch (e) {
                console.error(e);
                throw e;
            }

            chats = chats.map(
                chat => {
                    if (chat.name) {
                        chat.name = chat.name.replace("NAKAMA", "");
                        chat.name = chat.name.replace("Nakama", "");
                    }
                    return chat;
                }
            );

            let targetIndex = chats.findIndex(({ _id }) => _id === "62c766afb889f4387702c2f1");
            chats.unshift(Object.assign({}, chats[targetIndex]));
            chats.splice(targetIndex + 1, 1);

            targetIndex = chats.findIndex(({ _id }) => _id === "62c7a551b889f43877334caf");
            chats.unshift(Object.assign({}, chats[targetIndex]));
            chats.splice(targetIndex + 1, 1);

            context.commit("setChats", chats);
            return chats;
        },

        subscribeToChat (
            context,
            chatId,
        ) {
            let eventSource = subscribeToChat(chatId);
            context.commit(
                "subscribeToChat",
                {
                    chatId,
                    stream: eventSource
                }
            );
            return eventSource;
        },

        async sendMessage (
            context,
            { chatId, message }
        ) {
            let result;
            try {
                result = await sendMessage(
                    chatId,
                    message,
                );
            } catch (e) {
                console.error(e);
                throw e;
            }
            context.commit(
                "addMessage",
                {
                    chatId,
                    message: result
                }
            );
            return result;
        },

        async loadAccount (
            { commit },
            userId
        ) {
            const promise = getAccountById(userId,);
            commit("setAccountByUserId", { userId, promise });
            let account = null;
            try {
                account = await promise;
            } catch (e) {
                //console.error(e);
            }
            commit("setAccountByUserId", { userId, account });
            return account;
        },
    }
};

export default Whatsapp;
