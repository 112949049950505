import { getRegions, addRegion, editRegion, deleteRegion, } from "@/workers/region.worker";

const region = {
    namespaced: true,
    state: {
        regions: [],
        pagination: { total: 0, },
    },
    getters: {
        items ({ regions }) {
            return regions;
        },
        itemsActive ({ regions }) {
            return regions.filter(({ isActive }) => isActive);
        },
        itemById ({ regions }) {
            return id => regions.find(({ _id }) => _id === id);
        },
        itemByCode ({ regions }) {
            return id => regions.find(({ code }) => code === id);
        },
    },
    mutations: {
        setRegions (state, { total, data }) {
            state.regions = data;
            state.pagination.total = total;
        },
        addRegion (state, region) {
            state.regions.push(region);
        },
        editRegion (state, { regionId, region }) {
            state.regions.splice(
                state.regions.findIndex(({ _id }) => _id === regionId),
                1,
                region
            );
            state.regions.sort((a, b) => a.order - b.order);
        },
        deleteRegion (state, regionId) {
            state.regions
                .splice(
                    state.regions.findIndex(({ _id }) => _id === regionId),
                    1
                );
        },
    },
    actions: {
        async load ({ commit }) {
            try {
                const regions = await getRegions();
                commit("setRegions", regions);
                return regions;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async add ({ commit }, region) {
            try {
                const result = await addRegion(region);
                commit("addRegion", result);
                return result;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async edit ({ commit }, { _id, ...region }) {
            try {
                const result = await editRegion(_id, region);
                commit("editRegion", { "regionId": _id, "region": result });
                return result;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async delete ({ commit }, regionId) {
            try {
                await deleteRegion(regionId);
                commit("deleteRegion", regionId);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
    },
};

export default region;
